#contact-container {
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  margin-top: 2%;
}

#contact-content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 70vh;
}

.contact-photo {
  object-fit: contain;
  height: 100%;
}

#contact-buttons {
  width: 80vw;
  min-height: 20vh;
  display: flex;
  justify-content: flex-start;
}
