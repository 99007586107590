@font-face{
  font-family: 'Cera';
  src: url('../src/utils/Cera\ Pro\ Light.otf') format('opentype')
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Cera', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: 'Cera', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
