.nav-container {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 8vh;
  background-color: #000;
  z-index: 2;
}

.logo-box {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  height: 60%;
}

.logo:hover {
  cursor: pointer;
}

.nav-box-margin {
  width: 80vw;
  display: flex;
  justify-content: space-between;
}

ul {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 24%;
  list-style: none;
}


li:hover {
  cursor: pointer;
  text-decoration: overline;
  font-size: 1.1em;
}

#navbar-menu-button {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

@media(min-width: 1800px) {
  .nav-box-margin {
    width: 52vw;
  }
}

@media(max-width: 1000px) {
  .nav-container {
    display: none;
  }

  ul {
    width: 18%;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media(max-width: 500px) {
  .nav-container {
    display: flex;
  }

  ul {
    height: 100%;
    margin: 0;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;

  }
}
