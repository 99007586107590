.container-swiper {
  background-color: #121212;
  -webkit-box-shadow: 1px 1px 5px 4px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 5px 4px rgba(0,0,0,0.75);
  box-shadow: 18x 1px 5px 4px rgba(0,0,0,0.75);
  width: 80vw;
  height: 70vh;
}

.img-box{
  height: 90vh;
}

#photo-img{
  max-width: 80vw;
  height: 70vh;
  object-fit: contain;
}

.info-box{
  display: flex;
  justify-content: center;
  width: 100%;
  /* margin-top: 1%; */
}

.blur-effect{
  filter: blur(8px);
  transition: filter 0.3 ease-out;
}

.blur-effect.loaded {
  filter: none;
}

.fade-in-image {
 opacity: 0;
 transition: opacity 1s ease-in-out; /* Adjust the duration as needed */
}

@media(max-width: 1000px){
  .container-swiper{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photo-img{
    object-fit: contain;
  }

  .img-box{
    height: 98vh;
    width: 100%;
    justify-self: center;
    display: flex;
    justify-content: center; 
    align-items: center;
    margin: 0;
    padding: 0;
  }
}


@media(max-width: 500px){
  .photo-img{
    width: 100%;
  }

  .container-swiper{
    width: 90vw;
    height: 80vh;
    display: flex;
    justify-content: center; 
    align-items: center;
  }

  .img-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    justify-content: center; 
    align-items: center;
  }

  .info-box{
    margin-top: 0;
  }
}
