.info-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  margin-bottom: 3%;
  padding-top:2%;
}

@media(max-width: 1000px){
  .info-container{
    display: none;
  }
}

@media(max-width: 500px){
  .info-container{
    display: flex;
  }
}
