.Home {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

.home-body{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212;
}

#lazy-image-container{
  display: flex;
  align-items: center;
  justify-content:center;
  width: 80vw;
  height: 70vh;
}

@keyframes fade {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

#square{
  margin-top: 5%;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
}


.image{
max-width: 80vw;
  max-height: 70vh;
 object-fit: contain;
}

#controllers{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover{
  cursor: pointer;
  width: 2.0em;
  border: 1px solid #fff;
}

.CarouselContainer {
  background-color: #999;
  display: flex;
  justify-content: center;
  width: 80vw;
  height: 50vh;
}
