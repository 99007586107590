#admin-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  padding: 10%;
  border-radius: 4px;
}

input {
  width: 300px;
  height: 30px;
  border-radius: 4px;
  margin-bottom: 1%;
  text-align: center;
}

button {
  margin-top: 1%;
  height: 30px;
  border-radius: 4px;
  color: #fff;
  background-color: #7f7f7f;
}
