#card-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 6px rgba(0, 0, 0, 0.75);
  background-color: #3d3b40;
  margin: 1%;
}

#card-image {}

#card-text {
  color: #fff;
}
